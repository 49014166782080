import React from "react"
import Layout from "../components/layout"
import about from "./about.jpg"
import Image from "../components/image"

export default function About() {
  return (
    <Layout about={true}>
      <div style={{ display: "flex" }}>
        <div style={{ paddingRight: "50px" }}>
          <Image
            src={about}
            title="Central Park"
            subtitle="New York"
            date="07.19"
          />
        </div>
        <div>
          <p>Hello, thank you for visiting!</p>
          <p>
            My name is Carolyn and I have been sewing since 2016. I created this
            website to keep track of my sewing practice and to share photos of
            my projects with people who might be interested in them.
          </p>
          <p>
            If you’d like to read more about my sewing process, you can visit
            sewing.memos.earth. You can view my inspiration archive here, drop
            by design portfolio here, or email me here.
          </p>
        </div>
      </div>
    </Layout>
  )
}
